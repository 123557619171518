html,
body,
.Root,
.App {
  box-sizing: border-box;
  min-height: 100vh;
  font-family: "Quicksand", sans-serif;
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  margin: 0 !important;
}
.confirmAgeCont {
  font-family: "Quicksand", sans-serif;
}
.footer {
  text-align: center;
  margin-top: auto !important;
}
.row {
  margin: 0 !important;
  width: 100%;
}
.col {
  justify-content: center;
  align-items: center;
  margin-left: 0 !important;
  padding: 0 !important;
}
.yellow {
  color: #ffeb3b;
}
.value {
  width: 450px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 3em;
  border-radius: 1em;
  height: 650px;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(to right bottom, #a2c5fe, #c2e9fc);
}
.values {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  gap: 1em;
  margin: 1em;
}
.success,
.fail {
  border-radius: 1em;
  padding: 2em;
  width: 90%;
  margin: 0 auto;
}
.success p,
.fail p {
  margin: 0;
}
.success {
  background-image: linear-gradient(to right bottom, #a2c5fe, #c2e9fc);
}
.fail {
  background-image: linear-gradient(to right bottom, #f5c2c7, #f8d7da);
}
.office {
  width: 90%;
  margin: auto;
}
.cardBtm {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.cardBtm h2 {
  border-bottom: 1px solid black;
}
.missions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 90%;
}
.missionCenter {
  border-bottom: 1px solid black;
  border-top: 1px solid black;
}
.mission h2 {
  color: #71a2f1;
}
.mission p {
  font-size: large;
}
.title {
  width: 90%;
  margin: auto;
}
.title h2 {
  color: #71a2f1;
}
.title p {
  font-size: 1.4em;
}
.finalMessage {
  width: 90%;
  margin: auto;
  padding: 2em;
  background-image: linear-gradient(to right bottom, #a2c5fe, #c2e9fc);
  border-radius: 1em;
}
.svgWrap {
  border-radius: 1em;
  width: 60px;
  background-image: linear-gradient(to right bottom, #a2c5fe, #c2e9fc);
}
.missions svg {
  padding-left: 10px;
}

a.ig:hover {
  color: #833ab4 !important;
  transition: 0.5s;
}
a.facebook:hover {
  color: #0a66c2 !important;
  transition: 0.5s;
}
.footLinks {
  display: flex;
  justify-content: space-evenly;
}
.consult {
  border: 2px solid #71a2f1;
  border-radius: 1em;
  width: 90%;
  margin: auto;
}
.consult a {
  display: block;
  color: #71a2f1;
  text-decoration: none;
  padding: 0.7em;
  font-size: large;
  transition: 0.5s;
}
.consult:hover,
.consult:active {
  color: #3c85fa;
  border: 2px solid #3c85fa;
}
.aboutTitle,
.goals,
.aboutFinalMessage {
  width: 90%;
  margin: auto;
}
.aboutTitle p,
.goals li {
  font-size: medium;
}
.goals li {
  margin-top: 1em;
}
.goals,
.aboutFinalMessage {
  background-image: linear-gradient(to right bottom, #a2c5fe, #c2e9fc);
  padding: 1em;
  border-radius: 1em;
}
.navWrap {
  background-image: linear-gradient(to right bottom, #a2c5fe, #c2e9fc);
  width: 90%;
  margin: 0 auto;
  border-radius: 1em;
  margin-top: 2em;
}
.nav-link {
  font-size: large;
}
.navbar-brand {
  font-size: x-large !important;
}

.contactLink,
.contactLink:hover {
  text-decoration: none;
  font-size: 20px;
  color: black;
}
.formWrap {
  background-image: linear-gradient(to right bottom, #a2c5fe, #c2e9fc);
  border-radius: 1em;
  padding: 1.5em;
}
.newsTitle {
  border-bottom: 1px solid black;
}
.news {
  margin: auto;
  width: 90%;
}

.portrait img {
  border-radius: 1em;
}
.bio h3 {
  border-bottom: 1px solid black;
}
img {
  border-radius: 1em;
}
.aboutIntro {
  width: 90%;
  margin: auto;
}
.donateHome {
  width: min(90%, 800px);
  margin: 3rem auto;
}
.donateDiv {
  width: 100%;
  display: flex;
  justify-content: center;
}
.donateBtn {
  margin: 0.2rem;
  padding: 0.5rem 0.5rem;
  border: 2px solid #71a2f1;
  border-radius: 1rem;
  transition: 0.4s;
  color: #71a2f1 !important;
  margin-bottom: 2rem;
}
.donate {
  padding: 1rem;
}
.donateSide,
.donateAction {
  padding: 1rem;
  margin: 1rem;
  font-size: 18px;
  margin: 0 auto;
}
.donateSide {
  width: min(90%, 600px);
  display: flex;
  justify-content: center;
}

.donateAction {
  /* display: flex;
  gap: 2rem;
  align-items: center; */
  text-align: center;
  /* border-radius: 1rem;
  padding: 1rem;
  background-image: linear-gradient(to right bottom, #a2c5fe, #c2e9fc);
  width: 90%;
  margin: 0 auto;
  height: 130px; */
}
.donateAction a {
  margin: 0.2rem;
  padding: 0.5rem 0.5rem;
  border: 2px solid #008cff;
  border-radius: 1rem;
  transition: 0.4s;
  color: #71a2f1 !important;
}
.donateAction a:hover {
  border-color: #3946f1;
}

a {
  text-decoration: none !important;
}

.venmo > img {
  filter: grayscale();
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
@media screen and (max-width: 450px) {
  .value {
    font-size: 14px;
  }
  .title p {
    font-size: 16px;
  }
}
@media screen and (min-width: 500px) {
  .consult a {
    font-size: x-large;
  }
  .aboutTitle p,
  .goals li {
    font-size: large;
  }
  .donateAction a {
    padding: 1rem;
    margin: 1rem;
  }
}
@media screen and (max-width: 700px) {
  .value svg {
    display: none;
  }
}
@media screen and (min-width: 700px) {
  .contact {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .form {
    width: 600px;
  }
  .formWrap {
    padding: 3em;
  }
}
@media screen and (min-width: 768px) {
  .navWrap {
    width: 515px;
    margin-left: 5%;
  }
  .donateAction {
    width: 50%;
  }
}
@media screen and (min-width: 900px) {
  .missions,
  .title,
  .finalMessage,
  .aboutFinalMessage,
  .goals,
  .news,
  .success,
  .fail,
  .about,
  .office {
    width: 70%;
  }

  .consult {
    width: 50%;
  }
}
@media screen and (min-width: 1300px) {
  .bio p {
    font-size: large;
  }
}
@media screen and (min-width: 2000px) {
  .missions,
  .title,
  .finalMessage,
  .aboutFinalMessage,
  .goals,
  .news,
  .success,
  .fail,
  .about,
  .office {
    width: 50%;
  }

  .consult {
    width: 30%;
  }
  .bio p {
    font-size: x-large;
  }
}

.confirmAge {
  border-radius: 25px;
  width: 400px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-image: linear-gradient(to right bottom, #a2c5fe, #c2e9fc);
}

.confirmAgeCont {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.confirmAgeBtm {
  display: flex;
  justify-content: space-between;
  margin: 25px;
}

.ageBtn {
  width: 100px;
  height: 90px;
  margin: 0.2rem;
  padding: 0.5rem 0.5rem;
  border: 2px solid #008cff;
  border-radius: 25px;
  transition: 0.4s;
  color: #71a2f1;
  background-color: white;
  font-size: x-large;
}
.ageBtn:hover {
  border-color: #3946f1;
  color: #3946f1;
}

.donateAction a {
  margin: 0.2rem;
  padding: 0.5rem 0.5rem;
  border: 2px solid #008cff;
  border-radius: 1rem;
  transition: 0.4s;
  color: #71a2f1 !important;
}
.donateAction a:hover {
  border-color: #3946f1;
}

.notConfirmed {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 25px;
}
.liability {
  display: flex;
  height: 100vh;
  width: 100%;
  min-width: 750px;
  min-height: 750px;
  justify-content: center;
  margin: 25px;
  flex-direction: column;
}

.liabilityHome {
  display: flex;
  justify-content: center;
  width: 50%;
  flex-direction: column;
}

iframe {
  width: 90%;
  height: 100%;
  /* min-width: 750px;
  min-height: 750px; */
}
